import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Button, Checkbox, FilledInput, FormControlLabel, Typography } from '@mui/material';
import { publicConfig } from '@rs-app/lib/config';
import { setCookie } from '@rs-app/utils/cookie';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { CustomNextPage } from '@rs-app/@types';
import { getLayout } from '@rs-app/components/Layout';
import { Container } from '@rs-app/components/Common/Container';
import Head from 'next/head';

const AccessCodePage: CustomNextPage = () => {
  const router = useRouter();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(accessCodeSchema),
  });

  const redirectToHomepage = () => {
    router.push('/');
  };

  const setAccessCodeCookie = () => {
    setCookie(null, publicConfig.auth.accessCode.cookieName, 'True', {
      path: '/',
      domain: publicConfig.auth.cookieDomain,
    });
  };

  const setAccessCodeRememberMeCookie = () => {
    setCookie(null, publicConfig.auth.accessCode.rememberMeCookieName, 'True', {
      maxAge: 365 * 24 * 60 * 60,
      path: publicConfig.app.basePath,
      domain: publicConfig.auth.cookieDomain,
    });
  };

  const onSubmit = data => {
    // debugger;
    setAccessCodeCookie();
    if (data.rememberMe) {
      setAccessCodeRememberMeCookie();
    }
    redirectToHomepage();
  };

  return (
    <>
      <Head>
        <title>Roofstock onChain — Access Code</title>
      </Head>
      <Box component="main" paddingTop="2rem" paddingBottom="2rem">
        <Container>
          <Box textAlign="center" width="100%" maxWidth="600px" margin="0 auto">
            <form onSubmit={handleSubmit(onSubmit)}>
              <br />
              <Typography variant="body1" align="center">
                Enter RS onChain Access Code
              </Typography>
              <br />
              <FilledInput {...register('accessCode')} placeholder="Access Code" />
              {typeof errors.accessCode?.message === 'string' && (
                <>
                  <br />
                  <Typography variant="caption">{errors.accessCode.message}</Typography>
                </>
              )}
              <br />
              <FormControlLabel control={<Checkbox {...register('rememberMe')} />} label="Remember Me?" />
              <br />
              <br />
              <div>
                <Button type="submit" color="primary" variant="contained" size="medium">
                  Continue
                </Button>
              </div>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

AccessCodePage.getLayout = page =>
  getLayout(page, { minimalHeader: true, hideBecomeAMemberBanner: true, hideCloseButton: true });

export default AccessCodePage;

const accessCodeSchema = yup.object().shape({
  accessCode: yup.string().test('accessCode', 'Please enter a valid access code', value => {
    if (!value) {
      return false;
    }
    if (value) {
      const regEx = new RegExp(convertAccessCodesToRegExString());
      if (!regEx.test(value)) {
        return false;
      }
    }
    return true;
  }),
});

const convertAccessCodesToRegExString = () => {
  let accessCodesRegExString = '';
  const accessCodes = `${publicConfig.auth.accessCode.values}`.split(';');
  accessCodes.forEach(code => {
    if (code.length > 0) {
      const accessCodeRegEx = `(${code})`;
      accessCodesRegExString += `${accessCodeRegEx}|`;
    }
  });

  accessCodesRegExString = accessCodesRegExString.replace(/\|$/i, '');

  // console.log(`accessCodesRegExString=${accessCodesRegExString}`);
  // let reg = new RegExp(`${accessCodesRegExString}`, 'i');
  // console.log(`roofstock1 expect true=${reg.test('roofstock1')}`);
  // console.log(`1625: expect true=${reg.test('1625')}`);
  // console.log(`non: expect false=${reg.test('non')}`);

  return accessCodesRegExString;
};
